import React from 'react';

import Layout from '../components/Layout';

import mobileAppPerson from '../assets/images/mobile-app-person.png';
import monitor from '../assets/images/monitor.png';
import apiScreens from '../assets/images/api-screens.png';


import config from '../../config';
const IndexPage = () => (
  <Layout>
    <section id="banner">
      <div className="inner">
        <div className="logo">
          <span className="icon fa-code"></span>
        </div>
        <h2>{config.heading}</h2>
        <p>{config.subHeading}<br />{config.subHeading2}</p>
      </div>
    </section>

    <section id="wrapper">
     
      <section id="one" className="wrapper spotlight style1">
        <div className="inner">
          <a href="/#" className="image">
            <img src={mobileAppPerson} alt="" />
          </a>
          <div className="content">
            <h2 className="major">Mobile Apps & Websites</h2>
            <p>
              My range of services include design, engineering, testing, publishing, and consultation. 
              {/* I have the experience to build everything from landing pages to content management systems. */}
              {' '}All the products I build deliver an exceptional user experience through powerful features, intuitive functionality, and beautiful design.
            </p>
            {/* <a href="/#" className="special">
              Learn more
            </a> */}
          </div>
        </div>
      </section>

      <section id="two" className="wrapper alt spotlight style2">
        <div className="inner">
          <a href="/#" className="image">
            <img src={monitor} alt="" />
          </a>
          <div className="content">
            <h2 className="major">Server-Side</h2>
            <p>
              {/* From landing pages to content management systems -- I have over 7 years of experience building solutions that have complex business logic, large data sets, and high end.               */}
              I have the experience to build everything from landing pages to content management systems.
              {/* solutions that have complex business logic, large data sets, and high end. */}
            </p>
            {/* <a href="/#" className="special">
              Learn more
            </a> */}
          </div>
        </div>
      </section>

      <section id="three" className="wrapper spotlight style3">
        <div className="inner">
          <a href="/#" className="image">
            <img src={apiScreens} alt="" />
          </a>
          <div className="content">
            <h2 className="major">API Development</h2>
            <p>
              Lorem ipsum dolor sit amet, etiam lorem adipiscing elit. Cras
              turpis ante, nullam sit amet turpis non, sollicitudin posuere
              urna. Mauris id tellus arcu. Nunc vehicula id nulla dignissim
              dapibus. Nullam ultrices, neque et faucibus viverra, ex nulla
              cursus.
            </p>
            {/* <a href="/#" className="special">
              Learn more
            </a> */}
          </div>
        </div>
      </section>


      <section id="two" className="wrapper alt spotlight style2">
        <div className="inner">
          <a href="/#" className="image">
            <img src={monitor} alt="" />
          </a>
          <div className="content">
            <h2 className="major">Project Management</h2>
            <p>
              Lorem ipsum dolor sit amet, etiam lorem adipiscing elit. Cras
              turpis ante, nullam sit amet turpis non, sollicitudin posuere
              urna. Mauris id tellus arcu. Nunc vehicula id nulla dignissim
              dapibus. Nullam ultrices, neque et faucibus viverra, ex nulla
              cursus.
            </p>
            {/* <a href="/#" className="special">
              Learn more
            </a> */}
          </div>
        </div>
      </section>

      <section id="four" className="wrapper alt style1">
        <div className="inner">
          <h3 className="major" style={{ fontSize: 36 }}>Skills</h3>
          <p>
            Cras mattis ante fermentum, malesuada neque vitae, eleifend erat.
            Phasellus non pulvinar erat. Fusce tincidunt, nisl eget mattis
            egestas, purus ipsum consequat orci, sit amet lobortis lorem lacus
            in tellus. Sed ac elementum arcu. Quisque placerat auctor laoreet.
          </p>
          <section className="features">
            {config.skillSections.map((section, index) => 
              <article key={index}>
                <h2 className="major">{section.title}</h2>
                <ul>
                  {section.skills.map((skill, ind) => 
                    <li key={ind}>
                      {skill}
                    </li>
                  )}
                </ul>
              </article>
            )}
          </section>
        </div>
      </section>
    </section>
  </Layout>
);

export default IndexPage;
















/* 
 <section id="one" className="wrapper spotlight style1">
        <div className="inner">
          <a href="/#" className="image">
            <img src={mobileAppPerson} alt="" />
          </a>
          <div className="content">
            <h2 className="major">Mobile Application Development</h2>
            <p>
              My range of mobile app services include design, engineering, testing, publishing, and consultation.
              All the mobile apps I build deliver an exceptional user experience through powerful features, intuitive functionality, and beautiful design.
            </p>
       
            </div>
            </div>
          </section>
    
          <section id="two" className="wrapper alt spotlight style2">
            <div className="inner">
              <a href="/#" className="image">
                <img src={monitor} alt="" />
              </a>
              <div className="content">
                <h2 className="major">Web Development</h2>
                <p>
                  From landing pages to content management systems -- I have over 7 years of experience building solutions that have complex business logic, large data sets, and high end.
                  I have the experience to build everything from landing pages to content management systems.
                  solutions that have complex business logic, large data sets, and high end.
                </p>
     
              </div>
            </div>
          </section>
*/